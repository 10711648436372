import { Box, Container } from '@mui/material';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from './components/Header';
import JobOffers from './pages/JobOffersPage';
import JobPage from './pages/JobPage';
import ApplicationPage from './pages/ApplicationPage';
import ApplicationConfirmationPage from './pages/ApplicationConfirmationPage';
import BenefitsPage from './pages/BenefitsPage';
import PHATtesMiteinanderPage from './pages/PHATtesMiteinanderPage';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import DeclatationOfConsent from './pages/DeclatationOfConsent';
import PageNotFound from './pages/PageNotFound';
import ApplicationProcess from './pages/ApplicationProcessPage';
import JobFairForm from './pages/JobFairForm';

const App = (): JSX.Element => (
  <Box
    sx={{
      background:
        'linear-gradient(180deg, #344853ff 0%, #284250bb 40%, #284250bb 60%, #344853ff 100%)',
      backgroundSize: 'cover',
      minHeight: '100vh',
    }}
  >
    <BrowserRouter>
      <Header />

      <ScrollToTop />
      <Container maxWidth="md" style={{ marginTop: 50 }}>
        <Switch>
          <Route path="/" exact>
            <JobOffers />
          </Route>
          <Route path="/jobs/:offeringUrl">
            <JobPage />
          </Route>
          <Route path="/application/:offeringId?">
            <ApplicationPage />
          </Route>
          <Route path="/applicationConfirmation">
            <ApplicationConfirmationPage />
          </Route>
          <Route path="/pitch-club">
            <JobFairForm />
          </Route>
          <Route path="/pitch-club-confirmation">
              <ApplicationConfirmationPage />
            </Route>
          <Route path="/applicationProcess">
            <ApplicationProcess />
          </Route>
          <Route path="/PHATtesMiteinander">
            <PHATtesMiteinanderPage />
          </Route>
          <Route path="/Benefits">
            <BenefitsPage />
          </Route>
          <Route path="/declarationOfConsent">
            <DeclatationOfConsent />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Container>

      <Footer />
    </BrowserRouter>
  </Box>
);

export default App;
