import { Box } from '@mui/system';

import ApplicationConfirmationImage from '../resources/applicationConfirmationImage.jpeg';
import PitchClubConfirmationImage from '../resources/getTogetherSignUpThankYouImage.jpg';
import { useLocation } from 'react-router-dom';

const ApplicationConfirmationPage = (): JSX.Element => {

  const location = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#e3e3e3',
        margin: '3rem 0 5rem 0',
      }}
    >
      <img
        src={location.pathname === "/pitch-club-confirmation" ? PitchClubConfirmationImage : ApplicationConfirmationImage}
        alt="Foto von glücklichen PHATties"
        width="100%"
      />
    </Box>
  );
};

export default ApplicationConfirmationPage;
